import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import moment from 'moment-timezone';

import axios from 'api';

const initialState = {
  loading: false,
  data: [],
  success: false,
  errorMessage: null,
};

export const dataGet = createAsyncThunk('data/get', async ({ databaseName, urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    if (!databaseName) {
      return rejectWithValue({ message: 'Database name is required' });
    }

    let url = `/api/data/${databaseName}`;

    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);

    return {
      data: response.data,
      databaseName,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const dataGetLast = createAsyncThunk(
  'data/get/last',
  async ({ databaseName, relativeTime, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!databaseName && !relativeTime) {
        return rejectWithValue({ message: 'Database name and relative time are required' });
      }

      let url = `/api/data/${databaseName}/last/${relativeTime}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);

      return {
        data: response.data,
        databaseName,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const dataGetLatest = createAsyncThunk(
  'data/get/lastest',
  async ({ databaseName, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!databaseName) {
        return rejectWithValue({ message: 'Database name and relative time are required' });
      }

      let url = `/api/data/${databaseName}/latest`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);

      return {
        data: response.data,
        databaseName,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(dataGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(dataGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.data?.data || [];
      const { databaseName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
        };

        const existingIndex = state.data.findIndex(
          (entry) =>
            entry.local_time === newEntry.local_time &&
            entry.source === newEntry.source &&
            entry.type === newEntry.type &&
            entry.topic === newEntry.topic &&
            entry.databaseName === databaseName
        );

        if (existingIndex !== -1) {
          state.data[existingIndex] = modifiedEntry;
        } else {
          state.data.push(modifiedEntry);
        }
      });

      state.data.sort((a, b) => {
        return moment(a.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment(b.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      });
    });

    builder.addCase(dataGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.data = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(dataGetLast.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(dataGetLast.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data?.data || [];
      const { databaseName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
        };

        const existingIndex = state.data.findIndex(
          (entry) =>
            entry.local_time === newEntry.local_time &&
            entry.source === newEntry.source &&
            entry.type === newEntry.type &&
            entry.topic === newEntry.topic &&
            entry.databaseName === databaseName
        );

        if (existingIndex !== -1) {
          state.data[existingIndex] = modifiedEntry;
        } else {
          state.data.push(modifiedEntry);
        }
      });

      state.data.sort((a, b) => {
        return moment(a.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment(b.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      });
    });

    builder.addCase(dataGetLast.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.data = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(dataGetLatest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(dataGetLatest.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data || [];
      const { databaseName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
        };

        const existingIndex = state.data.findIndex(
          (entry) =>
            entry.local_time === newEntry.local_time &&
            entry.source === newEntry.source &&
            entry.type === newEntry.type &&
            entry.topic === newEntry.topic &&
            entry.databaseName === databaseName
        );

        if (existingIndex !== -1) {
          state.data[existingIndex] = modifiedEntry;
        } else {
          state.data.push(modifiedEntry);
        }
      });

      state.data.sort((a, b) => {
        return moment(a.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment(b.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      });
    });

    builder.addCase(dataGetLatest.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.data = [];
      state.errorMessage = action.payload;
    });
  },
});

export default dataSlice.reducer;
