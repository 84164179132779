import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  userPermissions: [],
  success: false,
  errorMessage: null,
};

export const userPermissionGet = createAsyncThunk(
  'userPermission/get',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      let url = '/api/user/permission';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userPermissionPost = createAsyncThunk(
  'userPermission/post',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/user/permission';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userPermissionDelete = createAsyncThunk(
  'userPermission/delete',
  async ({ databaseName, permissionType, tableName, userID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!userID || !databaseName || !tableName || !permissionType) {
        return rejectWithValue({ message: 'Missing required parameters' });
      }

      let url = `/api/user/permission/${userID}/${databaseName}/${tableName}/${permissionType}`;
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.delete(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userPermissionSlice = createSlice({
  name: 'userPermission',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userPermissionGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(userPermissionGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.userPermissions = action.payload.data;
    });

    builder.addCase(userPermissionGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(userPermissionPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(userPermissionPost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(userPermissionPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(userPermissionDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(userPermissionDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(userPermissionDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default userPermissionSlice.reducer;
