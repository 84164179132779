import { createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
  const color = colors;

  const themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backdrop: color.backdrop,
    backgroundDefault: color.primaryLight,
    background: color.paper,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    customization: {
      ...customization,
      darkMode: customization.darkMode !== null ? customization.darkMode : customization.prefersDarkMode,
    },
  };

  if (themeOption.customization.darkMode) {
    themeOption.colors = {
      ...themeOption.colors,
      paper: color.darkPaper,

      // primary
      primaryLight: color.darkPrimaryLight,
      primary200: color.darkPrimary200,
      primaryMain: color.darkPrimaryMain,
      primaryDark: color.darkPrimaryDark,
      primary800: color.darkPrimary800,

      // secondary
      secondaryLight: color.darkSecondaryLight,
      secondary200: color.darkSecondary200,
      secondaryMain: color.darkSecondaryMain,
      secondaryDark: color.darkSecondaryDark,
      secondary800: color.darkSecondary800,

      // success
      // successLight: color.darkSuccessLight,
      // success200: color.darkSuccess200,
      // successMain: color.darkSuccessMain,
      // successDark: color.darkSuccessDark,

      // error
      // errorLight: color.darkErrorLight,
      // errorMain: color.darkErrorMain,
      // errorDark: color.darkErrorDark,

      // orange
      // orangeLight: color.darkOrangeLight,
      // orangeMain: color.darkOrangeMain,
      // orangeDark: color.darkOrangeDark,

      // warning
      warningLight: color.darkWarningLight,
      warningMain: color.darkWarningMain,
      warningDark: color.darkWarningDark,

      // grey
      grey50: color.darkGrey50,
      // grey100: color.darkGrey100,
      // grey200: color.darkGrey200,
      // grey300: color.darkGrey300,
      // grey400: color.darkGrey400,
      // grey500: color.darkGrey500,
      // grey600: color.darkGrey600,
      grey700: color.darkGrey700,
      grey900: color.darkGrey900,
    };
    themeOption.heading = color.darkGrey900;
    themeOption.paper = color.darkPaper;
    themeOption.backdrop = color.darkBackdrop;
    themeOption.backgroundDefault = color.darkBackground;
    themeOption.background = color.darkPaper;
    themeOption.darkTextPrimary = color.darkTextPrimary;
    themeOption.darkTextSecondary = color.darkTextSecondary;
    themeOption.textDark = color.grey100;
    themeOption.menuSelected = color.darkSecondaryDark;
    themeOption.menuSelectedBack = color.darkSecondaryLight;
    themeOption.divider = color.darkDivider;
  }

  const themeOptions = {
    colorSchemes: {
      dark: true,
    },
    direction: 'ltr',
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '10px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
    typography: themeTypography(themeOption),
  };

  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;
