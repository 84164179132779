// sessionManager.js
import Cookies from 'js-cookie';

const TOKEN_KEY = 'jwtToken';
const USER_KEY = 'userInfo';

export const sessionManager = {
  // Store the token and user data in localStorage or cookies
  setSession: (token, user, rememberMe = false) => {
    if (rememberMe) {
      // Save to Cookies
      Cookies.set(TOKEN_KEY, token, { expires: 7 });
      Cookies.set(USER_KEY, JSON.stringify(user), { expires: 7 });
    } else {
      // Otherwise, use localStorage
      localStorage.setItem(TOKEN_KEY, token);
      localStorage.setItem(USER_KEY, JSON.stringify(user));
    }
  },

  // Get the token from localStorage or cookies
  getToken: () => {
    return Cookies.get(TOKEN_KEY) || localStorage.getItem(TOKEN_KEY);
  },

  // Get user data from localStorage or cookies
  getUser: () => {
    const userData = Cookies.get(USER_KEY) || localStorage.getItem(USER_KEY);
    return userData ? JSON.parse(userData) : null;
  },

  // Update user information in storage
  updateUser: (user) => {
    if (Cookies.get(TOKEN_KEY)) {
      Cookies.set(USER_KEY, JSON.stringify(user), { expires: 7 });
    } else if (localStorage.getItem(TOKEN_KEY)) {
      localStorage.setItem(USER_KEY, JSON.stringify(user));
    }
  },

  updateToken: (token) => {
    if (Cookies.get(TOKEN_KEY)) {
      Cookies.set(TOKEN_KEY, token, { expires: 7 });
    } else {
      localStorage.setItem(TOKEN_KEY, token);
    }
  },

  // Check if the user is authenticated (i.e., token exists)
  isAuthenticated: () => {
    return !!(localStorage.getItem(TOKEN_KEY) || Cookies.get(TOKEN_KEY));
  },

  // Clear the session (logout)
  clearSession: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    Cookies.remove(TOKEN_KEY);
    Cookies.remove(USER_KEY);
  },
};
