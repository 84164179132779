import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  widgets: [],
  error: null,
  success: false,
  errorMessage: null,
};

export const widgetGet = createAsyncThunk('widget/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/widget';

    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const widgetPost = createAsyncThunk('widget/post', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/widget';

    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.post(url, payload, config).then((response) => ({
      data: response?.data,
      payload: payload.widgets,
    }));
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const widgetPut = createAsyncThunk('widget/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/widget';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.put(url, payload, config).then((response) => ({
      data: response?.data,
      payload,
    }));
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const widgetDelete = createAsyncThunk(
  'widget/delete',
  async ({ widgetID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!widgetID) {
        return rejectWithValue({ message: 'Parameter is required' });
      }

      let url = `/api/widget/${widgetID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }

      return await axios.delete(url, config).then((response) => ({
        data: response?.data,
        widgetID,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(widgetGet.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(widgetGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.data?.data || [];

      newEntries.forEach((newEntry) => {
        const existingIndex = state.widgets.findIndex((entry) => entry.widget_id === newEntry.widget_id);
        if (existingIndex !== -1) {
          state.widgets[existingIndex] = newEntry;
        } else {
          state.widgets.push(newEntry);
        }
      });

      state.widgets.sort((a, b) => a.widget_id < b.widget_id);
    });

    builder.addCase(widgetGet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(widgetPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(widgetPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const insertedIds = action.payload.data.insertedIds;

      const newDashboards = action.payload.payload.map((widget, index) => ({
        ...widget,
        widget_id: insertedIds[index],
      }));

      state.widgets.push(...newDashboards);
    });

    builder.addCase(widgetPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(widgetPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(widgetPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.widgets.forEach((widget) => {
        const index = state.widgets.findIndex((entry) => entry.widget_id === widget.widget_id);
        if (index !== -1) {
          state.widgets[index] = widget;
        }
      });
    });

    builder.addCase(widgetPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(widgetDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(widgetDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.widgets = state.widgets.filter((widget) => widget.widget_id !== action.payload.widgetID);
    });

    builder.addCase(widgetDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default widgetSlice.reducer;
