import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  topics: [],
  success: false,
  error: null,
  errorMessage: null,
};

export const topicGet = createAsyncThunk('topic/get', async ({ databaseName, urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    if (!databaseName) {
      return rejectWithValue({ message: 'Database name is required' });
    }

    let url = `/api/topic/${databaseName}`;

    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);

    return {
      ...response,
      databaseName,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const topicPost = createAsyncThunk(
  'topic/post',
  async ({ databaseName, urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!databaseName) {
        return rejectWithValue({ message: 'Database name is required' });
      }

      let url = `/api/topic/${databaseName}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const topicPut = createAsyncThunk(
  'topic/put',
  async ({ databaseName, urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!databaseName) {
        return rejectWithValue({ message: 'Database name is required' });
      }

      let url = `/api/topic/${databaseName}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.put(url, payload, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const topicDelete = createAsyncThunk(
  'topic/delete',
  async ({ databaseName, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!databaseName) {
        return rejectWithValue({ message: 'Database name is required' });
      }

      let url = `/api/topic/${databaseName}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.delete(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const topicSlice = createSlice({
  name: 'topic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(topicGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(topicGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data?.data || [];
      const { databaseName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
        };

        const existingIndex = state.topics.findIndex((entry) => entry.topic === newEntry.topic && entry.databaseName === databaseName);

        if (existingIndex !== -1) {
          state.topics[existingIndex] = modifiedEntry;
        } else {
          state.topics.push(modifiedEntry);
        }
      });

      state.topics.sort((a, b) => {
        return a.topic.localeCompare(b.topic);
      });
    });

    builder.addCase(topicGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.topics = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(topicPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(topicPost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(topicPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(topicPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(topicPut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(topicPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(topicDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(topicDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(topicDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default topicSlice.reducer;
