import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  dataTypes: [],
  error: null,
  success: false,
  errorMessage: null,
};

export const dataTypeGet = createAsyncThunk('dataType/get', async ({ databaseName, urlParam, queryParams }, { rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: queryParams,
  };
  try {
    let url = '/api/datatype';
    if (databaseName) {
      url += `/${databaseName}`;
    }
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);

    return {
      ...response,
      databaseName,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const dataTypePost = createAsyncThunk(
  'dataType/post',

  async ({ databaseName, urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',

        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/datatype';
      if (databaseName) {
        url += `/${databaseName}`;
      }
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);

      return {
        ...response,
        payload,
        databaseName,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const dataTypePut = createAsyncThunk(
  'dataType/put',

  async ({ databaseName, urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',

        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/datatype';
      if (databaseName) {
        url += `/${databaseName}`;
      }
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.put(url, payload, config);
      return {
        ...response,
        payload,
        databaseName,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const dataTypeSlice = createSlice({
  name: 'dataType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(dataTypeGet.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(dataTypeGet.fulfilled, (state, action) => {
      state.loading = false;

      const newEntries = action.payload.data?.data || [];
      const { databaseName } = action.payload;
      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
        };

        const existingIndex = state.dataTypes.findIndex(
          (entry) => entry.type === modifiedEntry.type && entry.databaseName === databaseName
        );
        if (existingIndex !== -1) {
          state.dataTypes[existingIndex] = modifiedEntry;
        } else {
          state.dataTypes.push(modifiedEntry);
        }
      });

      state.dataTypes.sort((a, b) => a.type.localeCompare(b.type));
    });

    builder.addCase(dataTypeGet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(dataTypePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(dataTypePost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.types.forEach((type) => {
        state.dataTypes.push({
          ...type,
          databaseName: action.payload.databaseName,
        });
      });
      state.dataTypes.sort((a, b) => a.type.localeCompare(b.type));
    });

    builder.addCase(dataTypePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(dataTypePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(dataTypePut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.payload?.types.forEach((type) => {
        const index = state.dataTypes.findIndex((entry) => entry.type === type.type && entry.databaseName === action.payload.databaseName);
        if (index !== -1) {
          state.dataTypes[index] = {
            ...state.dataTypes[index],
            ...type,
            databaseName: action.payload.databaseName,
          };
        }
      });
    });

    builder.addCase(dataTypePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default dataTypeSlice.reducer;
