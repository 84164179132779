const blurEffect = ({ blurStrength = 20, backgroundOpacity = 0.6, theme }) => ({
  backdropFilter: `blur(${blurStrength}px)`,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? `rgba(31, 31, 31, ${backgroundOpacity})` // Dark mode background
      : `rgba(255, 255, 255, ${backgroundOpacity})`, // Light mode background
  border:
    theme.palette.mode === 'dark'
      ? '1px solid rgba(255, 255, 255, 0.1)' // Subtle white border for dark mode
      : '1px solid rgba(255, 255, 255, 0.3)', // Light mode border
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 4px 30px rgba(0, 0, 0, 0.5)' // Softer shadow for dark mode
      : '0 4px 30px rgba(0, 0, 0, 0.1)', // Lighter shadow for light mode
});

export default blurEffect;
