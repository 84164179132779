import { useSelector } from 'react-redux';

const useUserRole = (orgID) => {
  const { account } = useSelector((state) => state.account);
  if (orgID === 'public') {
    return 'member';
  }
  return account?.role === 'superuser' ? 'superuser' : account.organizations.find(({ organization }) => organization === orgID)?.role;
};

export default useUserRole;
