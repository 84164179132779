import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';

const UserAvatar = ({ email, profilePicture, size, sx }) => {
  return (
    <Avatar
      sx={sx}
      src={profilePicture ? profilePicture : undefined} // use profilePicture if it exists
      alt={email}
    >
      {!profilePicture && <Gravatar email={email} size={size} fallback={email} />}
    </Avatar>
  );
};

UserAvatar.propTypes = {
  email: PropTypes.string.isRequired,
  profilePicture: PropTypes.string, // profilePicture is optional
  size: PropTypes.number,
  sx: PropTypes.object,
};

UserAvatar.defaultProps = {
  size: 34, // default Gravatar size
  sx: {}, // default styles
};

export default UserAvatar;
