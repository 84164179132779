import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  userRoles: [],
  success: false,
  errorMessage: null,
};

export const userRoleGet = createAsyncThunk('userRole/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/user/role';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const userRolePost = createAsyncThunk('userRole/post', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/user/role';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.post(url, payload, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const userRolePut = createAsyncThunk('userRole/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/user/role';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.put(url, payload, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const userRoleDelete = createAsyncThunk(
  'userRole/delete',
  async ({ orgID, userID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!userID && !orgID) {
        return rejectWithValue({ message: 'Database name and table name are required' });
      }

      let url = `/api/user/role/${userID}/${orgID}`;
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.delete(url, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userRoleSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userRoleGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(userRoleGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.userRoles = action.payload.data;
    });

    builder.addCase(userRoleGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(userRolePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(userRolePost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(userRolePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(userRolePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(userRolePut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(userRolePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(userRoleDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(userRoleDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(userRoleDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default userRoleSlice.reducer;
