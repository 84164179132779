import { sessionManager } from 'utils/sessionManager';
import { accountGetDetails, updateToken } from 'features/account/accountSlice';

const handleAuthentication = async (dispatch, token) => {
  // You might want to dispatch the action to set the token in Redux state
  await dispatch(updateToken(token)); // This sets the token in Redux state

  try {
    // Assuming your API can verify the token and return user details
    const userDetails = await dispatch(accountGetDetails()).unwrap();

    // Only set the session if user details are successfully retrieved
    sessionManager.setSession(token, userDetails);

    // Optionally, you can handle the navigation or state update after successful login
  } catch (error) {
    console.error('Failed to retrieve user details:', error);
    // Handle error accordingly (e.g., show a notification)
  }
};

export default handleAuthentication;
