import { lazy } from 'react';

// project imports

import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import WithAuthentication from 'components/withAuthentication';

// dashboard routing
const Start = Loadable(lazy(() => import('views/start')));
const JoinInvite = Loadable(lazy(() => import('views/joinInvite')));
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// // sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <WithAuthentication>
      <MinimalLayout />
    </WithAuthentication>
  ),
  children: [
    {
      path: '/',
      element: <Start />,
    },
    {
      path: 'join',
      element: <JoinInvite />,
    },
  ],
};

export default MainRoutes;
