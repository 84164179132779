import { IconChartArea, IconChartBar, IconChartHistogram, IconHome, IconMap } from '@tabler/icons-react';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = ({ orgID }) => ({
  id: 'group-home',
  title: '',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      url: `/app/${orgID}/home`,
      icon: IconHome,
      breadcrumbs: false,
    },
    {
      id: 'graph-group',
      title: 'Graphs',
      type: 'collapse',
      icon: IconChartHistogram,
      children: [
        {
          id: 'raw',
          title: 'Raw',
          type: 'item',
          url: `/app/${orgID}/graph/raw`,
          icon: IconChartArea,
          breadcrumbs: true,
        },
        {
          id: 'sensorinsights',
          title: 'Sensor Insights',
          type: 'item',
          url: `/app/${orgID}/graph/sensorinsights`,
          icon: IconChartBar,
          breadcrumbs: true,
        },
      ],
    },
    {
      id: 'map',
      title: 'Map',
      type: 'item',
      url: `/app/${orgID}/map`,
      icon: IconMap,
      breadcrumbs: false,
    },
  ],
});

export default dashboard;
