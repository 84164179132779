// material-ui
import { ListItemButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import PropTypes from 'prop-types';

// ==============================|| BLUR DIALOG ||============================== //

const NavListItemButton = ({ sx, ...others }) => {
  const theme = useTheme();

  const buttonSx = {
    ...sx,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '& .MuiListItemIcon-root': {
        color: theme.palette.text.primary,
      },
    },
  };

  return <ListItemButton sx={buttonSx} {...others} />;
};

NavListItemButton.propTypes = {
  sx: PropTypes.object,
};

export default NavListItemButton;
