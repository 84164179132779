import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'api';
import { sessionManager } from 'utils/sessionManager';

// initialize userToken from sessionManager
const token = sessionManager.getToken();
const isAuthenticated = sessionManager.isAuthenticated();

const initialState = {
  loading: false,
  account: null,
  token,
  isAuthenticated,
  success: false,
  errorMessage: null,
};

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// Generates pending, fulfilled and rejected action types
export const accountRegister = createAsyncThunk('account/register', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/api/user/register`, payload, config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data); // Return the error response data as the payload
  }
});

export const accountLogin = createAsyncThunk('account/login', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/api/user/login`, { email: payload.email, password: payload.password }, config);
    sessionManager.setSession(response.data.token, response.data.user, payload.rememberMe);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data); // Return the error response data as the payload
  }
});

export const accountConfirm = createAsyncThunk(
  'account/confirm',
  async ({ token, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!token) {
        return rejectWithValue({ message: 'Token is required' });
      }
      let url = `/api/user/confirm/${token}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.get(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const accountForgot = createAsyncThunk(
  'account/forgot',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/user/forgot';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.post(url, payload, config).then((response) => ({
        data: response.data,
        payload,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const accountResend = createAsyncThunk(
  'account/resend',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/user/resend';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.post(url, payload, config).then((response) => ({
        data: response.data,
        payload,
      }));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const accountReset = createAsyncThunk('account/reset', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/user/reset';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.post(url, payload, config).then((response) => ({
      data: response.data,
      payload,
    }));
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const accountGetDetails = createAsyncThunk('account/getDetails', async () => {
  const token = sessionManager.getToken(); // Get token from sessionManager
  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };
  const response = await axios.get(`/api/user/profile`, config);
  return response.data;
});

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    logout: (state) => {
      state.loading = false;
      state.account = null;
      state.token = null;
      state.isAuthenticated = false;
      sessionManager.clearSession(); // Clear session via sessionManager
    },
    updateAccount: (state, action) => {
      state.account = action.payload;
      sessionManager.updateUser(action.payload);
    },
    updateToken: (state, action) => {
      state.token = action.payload;
      sessionManager.updateToken(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(accountRegister.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(accountRegister.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(accountRegister.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(accountLogin.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(accountLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload.token;
      state.account = action.payload.user;
      state.isAuthenticated = true;
    });

    builder.addCase(accountLogin.rejected, (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.success = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(accountGetDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(accountGetDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.account = action.payload;
      state.isAuthenticated = true;
      state.account = action.payload;
      sessionManager.updateUser(action.payload);
    });

    builder.addCase(accountGetDetails.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(accountConfirm.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(accountConfirm.fulfilled, (state, action) => {
      state.loading = false;
      state.account = action.payload;
    });

    builder.addCase(accountConfirm.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(accountForgot.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(accountForgot.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(accountForgot.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(accountResend.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(accountResend.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(accountResend.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(accountReset.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(accountReset.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(accountReset.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { logout, updateAccount, updateAuthenticated, updateToken } = accountSlice.actions;
export default accountSlice.reducer;
