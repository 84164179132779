import {
  IconBraces,
  IconBracketsAngle,
  IconBriefcase,
  IconBriefcase2,
  IconBox,
  IconBuildingBank,
  IconChartArrowsVertical,
  IconCpu,
  IconDatabase,
  IconDatabaseCog,
  IconDevices,
  IconDevicesCode,
  IconDevicesCog,
  IconLink,
  IconLockAccess,
  IconLockQuestion,
  IconMap2,
  IconMapCode,
  IconMapPin,
  IconMapPins,
  IconMessage,
  IconMessageDown,
  IconPackage,
  IconPencilQuestion,
  IconTableColumn,
  IconTableRow,
  IconRouter,
  IconStack2,
  IconTableAlias,
  IconUser,
  IconUsers,
  IconUsersGroup,
} from '@tabler/icons-react';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const platform = ({ database, device, location, organization, orgID, role, service, subscriber, user }) => {
  const menuItem = [];

  if (role === 'superuser' && orgID === 'superuser') {
    // menuItem.push({
    //   id: 'organizations',
    //   title: 'Organizations',
    //   type: 'item',
    //   url: '/app/superuser/organizations',
    //   icon: IconBuildingBank,
    //   breadcrumbs: true,
    // });

    let organizationsItem = {
      id: 'organizations-group',
      title: 'Workspaces',
      icon: IconBuildingBank,
      breadcrumbs: true,
      type: 'collapse',
      children: [],
    };

    let organizationsListItem = {
      id: 'organizations',
      title: 'All Workspaces',
      icon: IconBriefcase,
      breadcrumbs: true,
      type: 'item',
      url: `/app/superuser/organizations`,
    };

    if (organization) {
      organizationsListItem.type = 'collapse';
      organizationsListItem.children = [
        {
          id: `${organization.organization}`,
          title: organization.description || 'Workspace',
          type: 'item',
          url: `/app/${orgID}/organization/${organization.organization}`,
          icon: IconBriefcase2,
          breadcrumbs: true,
        },
      ];
    } else {
      organizationsListItem.type = 'item';
    }

    organizationsItem.children.push(organizationsListItem);

    organizationsItem.children.push({
      id: 'organizations-requests',
      title: 'Requests',
      icon: IconPencilQuestion,
      breadcrumbs: true,
      type: 'item',
      url: `/app/superuser/organizationsrequests`,
    });

    menuItem.push(organizationsItem);
  }

  let dataItem = {
    id: 'data-group',
    title: 'Data Management',
    type: 'collapse',
    icon: IconTableAlias,
    breadcrumbs: true,
    children: [],
  };

  if (role === 'superuser' || role === 'admin' || role === 'member') {
    let databases = {
      id: 'databases',
      title: 'Databases',
      icon: IconDatabaseCog,
      breadcrumbs: true,
      type: 'item',
      url: `/app/${orgID}/databases`,
    };

    if (database) {
      databases.type = 'collapse';
      databases.children = [
        {
          id: `${database.database_name}`,
          title: database.database_name || 'Database',
          type: 'item',
          url: `/app/${orgID}/database/${database.database_name}`,
          icon: IconDatabase,
          breadcrumbs: true,
        },
      ];
    } else {
      databases.type = 'item';
    }

    dataItem.children.push(databases);
  }

  if (role === 'superuser' || role === 'admin' || role === 'member') {
    dataItem.children.push(
      {
        id: 'datatypes',
        title: 'Data Types',
        type: 'item',
        url: `/app/${orgID}/datatypes`,
        icon: IconBraces,
        breadcrumbs: true,
      },
      {
        id: 'data',
        title: 'Data',
        type: 'item',
        url: `/app/${orgID}/data`,
        icon: IconTableRow,
        breadcrumbs: true,
      },
      {
        id: 'measurements',
        title: 'Measurements',
        type: 'item',
        url: `/app/${orgID}/measurements`,
        icon: IconTableColumn,
        breadcrumbs: true,
      },
      {
        id: 'sensorinsights',
        title: 'Sensor Insights',
        type: 'item',
        url: `/app/${orgID}/sensorinsights`,
        icon: IconChartArrowsVertical,
        breadcrumbs: true,
      }
    );
  }

  if (role === 'superuser' && orgID === 'superuser') {
    dataItem.children.push({
      id: 'parameters',
      title: 'Parameters',
      type: 'item',
      url: `/app/${orgID}/parameters`,
      icon: IconBracketsAngle,
      breadcrumbs: true,
    });
  }

  menuItem.push(dataItem);

  let deviceItem = {
    id: 'devices-group',
    title: 'Devices',
    type: 'collapse',
    icon: IconDevices,
    breadcrumbs: true,
    children: [],
  };

  if (role === 'superuser' && orgID === 'superuser') {
    deviceItem.children.push({
      id: 'devicetypes',
      title: 'Device Types',
      type: 'item',
      url: `/app/superuser/devicetypes`,
      icon: IconDevicesCode,
    });
  }

  if (role === 'superuser' || role === 'admin' || role === 'member') {
    let devicesList = {
      id: 'devices',
      title: 'Device List',
      icon: IconDevicesCog,
      breadcrumbs: true,
      url: `/app/${orgID}/devices`,
    };

    if (device) {
      devicesList.type = 'collapse';
      devicesList.children = [
        {
          id: `${device.device_id}`,
          title: device.device_name || `Device ${device.device_id}`,
          type: 'item',
          url: `/app/${orgID}/device/${device.device_id}`,
          icon: IconRouter,
          breadcrumbs: true,
        },
      ];
    } else {
      devicesList.type = 'item';
    }

    deviceItem.children.push(devicesList);
  }

  menuItem.push(deviceItem);

  let locationItem = {
    id: 'locations-group',
    title: 'Locations',
    type: 'collapse',
    icon: IconMap2,
    breadcrumbs: true,
    children: [],
  };

  if (role === 'superuser' && orgID === 'superuser') {
    locationItem.children.push({
      id: 'locationtypes',
      title: 'Location Types',
      type: 'item',
      url: `/app/superuser/locationtypes`,
      icon: IconMapCode,
    });
  }

  if (role === 'superuser' || role === 'admin' || role === 'member') {
    let locationsList = {
      id: 'locations',
      title: 'Location List',
      icon: IconMapPins,
      breadcrumbs: true,
      url: `/app/${orgID}/locations`,
    };

    if (location) {
      locationsList.type = 'collapse';
      locationsList.children = [
        {
          id: `${location.location_id}`,
          title: location.location_name || `Location ${location.location_id}`,
          type: 'item',
          url: `/app/${orgID}/location/${location.location_id}`,
          icon: IconMapPin,
          breadcrumbs: true,
        },
      ];
    } else {
      locationsList.type = 'item';
    }

    locationItem.children.push(locationsList);
  }

  menuItem.push(locationItem);

  let userItem = {
    id: 'user-group',
    title: 'Users',
    type: 'collapse',
    icon: IconUsersGroup,
    breadcrumbs: true,
    children: [],
  };

  let usersList = {
    id: 'users',
    title: 'User List',
    icon: IconUsers,
    breadcrumbs: true,
    url: `/app/${orgID}/users`,
  };

  if (user) {
    usersList.type = 'collapse';
    usersList.children = [
      {
        id: `${user.user_id}`,
        title: `${user.name_family}, ${user.name_given}` || `User ${user.user_id}`,
        type: 'item',
        url: `/app/${orgID}/profile/${user.user_id}`,
        icon: IconUser,
        breadcrumbs: true,
      },
    ];
  } else {
    usersList.type = 'item';
  }

  userItem.children.push(
    usersList,
    {
      id: 'permissions',
      title: 'Permissions',
      type: 'item',
      url: `/app/${orgID}/permissions`,
      icon: IconLockAccess,
      breadcrumbs: true,
    },
    {
      id: 'requests',
      title: 'Access Requests',
      type: 'item',
      url: `/app/${orgID}/users/requests`,
      icon: IconLockQuestion,
      breadcrumbs: true,
    },
    {
      id: 'invites',
      title: 'Invite Links',
      type: 'item',
      url: `/app/${orgID}/users/invites`,
      icon: IconLink,
      breadcrumbs: true,
    }
  );

  if (role === 'superuser' || role === 'admin') {
    menuItem.push(userItem);
  }

  let serviceItem = {
    id: 'services-group',
    title: 'Services',
    type: 'collapse',
    icon: IconStack2,
    breadcrumbs: true,
    children: [],
  };

  let subscriberItem = {
    id: 'subscribers',
    title: 'Subscribers',
    icon: IconMessageDown,
    url: `/app/${orgID}/subscribers`,
    breadcrumbs: true,
  };

  if (subscriber) {
    subscriberItem.type = 'collapse';
    subscriberItem.children = [
      {
        id: `${subscriber.sub_id}`,
        title: subscriber.container_name || `Subscriber ${subscriber.sub_id}`,
        type: 'item',
        url: `/app/${orgID}/subscriber/${subscriber.sub_id}`,
        icon: IconMessage,
        breadcrumbs: true,
      },
    ];
  } else {
    subscriberItem.type = 'item';
  }

  let otherServiceItem = {
    id: 'services',
    title: 'Other Services',
    icon: IconPackage,
    url: `/app/${orgID}/services`,
    breadcrumbs: true,
  };

  if (service) {
    otherServiceItem.type = 'collapse';
    otherServiceItem.children = [
      {
        id: `${service.service_id}`,
        title: service.container_name || `Service ${service.service_id}`,
        type: 'item',
        url: `/app/${orgID}/service/${service.service_id}`,
        icon: IconBox,
        breadcrumbs: true,
      },
    ];
  } else {
    otherServiceItem.type = 'item';
  }

  if (role === 'superuser' || role === 'admin') {
    serviceItem.children.push(subscriberItem);
    serviceItem.children.push(otherServiceItem);
    menuItem.push(serviceItem);
  }

  if (role === 'superuser' && orgID === 'superuser') {
    menuItem.push({
      id: 'firmware',
      title: 'Firmware',
      type: 'item',
      url: '/app/superuser/firmware',
      icon: IconCpu,
      breadcrumbs: true,
    });
  }

  return {
    id: 'platform-group',
    title: 'Platform',
    type: 'group',
    children: menuItem,
  };
};

export default platform;
