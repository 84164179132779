import { combineReducers } from '@reduxjs/toolkit';
// import { createReducer } from '@reduxjs/toolkit';

// reducer import
import customizationReducer from './customizationReducer';
import accountSlice from 'features/account/accountSlice';
import dashboardSlice from 'features/dashboard/dashboardSlice';
import dataSlice from 'features/data/dataSlice';
import databaseSlice from 'features/database/databaseSlice';
import dataTypeSlice from 'features/dataType/dataTypeSlice';
import deviceSlice from 'features/device/deviceSlice';
import firmwareSlice from 'features/firmware/firmwareSlice';
import grafanaSlice from 'features/grafana/grafanaSlice';
import locationSlice from 'features/location/locationSlice';
import measurementSlice from 'features/measurement/measurementSlice';
import organizationSlice from 'features/organization/organizationSlice';
import parameterSlice from 'features/parameter/parameterSlice';
import serviceSlice from 'features/service/serviceSlice';
import sensorInsightsSlice from 'features/sensorInsights/sensorInsightsSlice';
import sourceSlice from 'features/source/sourceSlice';
import subscriberSlice from 'features/subscriber/subscriberSlice';
import topicSlice from 'features/topic/topicSlice';
import userSlice from 'features/user/userSlice';
import userPermissionSlice from 'features/userPermission/userPermissionSlice';
import userRoleSlice from 'features/userRole/userRoleSlice';
import widgetSlice from 'features/widget/widgetSlice';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  account: accountSlice,
  dashboard: dashboardSlice,
  data: dataSlice,
  database: databaseSlice,
  dataType: dataTypeSlice,
  device: deviceSlice,
  firmware: firmwareSlice,
  grafana: grafanaSlice,
  location: locationSlice,
  measurement: measurementSlice,
  organization: organizationSlice,
  parameter: parameterSlice,
  service: serviceSlice,
  sensorInsights: sensorInsightsSlice,
  source: sourceSlice,
  subscriber: subscriberSlice,
  topic: topicSlice,
  user: userSlice,
  userPermission: userPermissionSlice,
  userRole: userRoleSlice,
  widget: widgetSlice,
});

export default reducer;
