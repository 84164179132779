import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  subscribers: [],
  success: false,
  containers: [],
  logs: '',
  stats: {},
  errorMessage: null,
};

export const subscriberGet = createAsyncThunk('subscriber/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/subscriber';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const subscriberPost = createAsyncThunk(
  'subscriber/post',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/subscriber';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return {
        ...response,
        subscribers: payload?.subscribers,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberPut = createAsyncThunk(
  'subscriber/put',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/subscriber';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.put(url, payload, config);
      return {
        ...response,
        subscribers: payload?.subscribers,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberDelete = createAsyncThunk(
  'subscriber/delete',
  async ({ subID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!subID) {
        return rejectWithValue({ message: 'Subscriber ID is required' });
      }

      let url = `/api/subscriber/${subID}`;
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.delete(url, config);
      return {
        ...response,
        subID,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberPostContainerStart = createAsyncThunk(
  'subscriber/post/container/start',
  async ({ subID, urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!subID) {
        return rejectWithValue({ message: 'Subscriber ID is required' });
      }

      let url = `/api/subscriber/start/${subID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.post(url, payload, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberPostContainerStop = createAsyncThunk(
  'subscriber/post/container/stop',
  async ({ subID, urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!subID) {
        return rejectWithValue({ message: 'Subscriber ID is required' });
      }

      let url = `/api/subscriber/stop/${subID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.post(url, payload, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberGetContainerList = createAsyncThunk(
  'subscriber/get/container/list',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      let url = '/api/subscriber/list';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.get(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberGetContainerLogs = createAsyncThunk(
  'subscriber/get/container/logs',
  async ({ subID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!subID) {
        return rejectWithValue({ message: 'Subscriber ID is required' });
      }

      let url = `/api/subscriber/logs/${subID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.get(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberGetContainerStats = createAsyncThunk(
  'subscriber/get/container/stats',
  async ({ subID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!subID) {
        return rejectWithValue({ message: 'Subscriber ID is required' });
      }

      let url = `/api/subscriber/stats/${subID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.get(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const subscriberSlice = createSlice({
  name: 'subscriber',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(subscriberGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(subscriberGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload?.data || [];

      state.subscribers = [
        ...state.subscribers.map((subscriber) => {
          // Find a matching new entry based on subscriber_id
          const matchingEntry = newEntries.find((newEntry) => newEntry.sub_id === subscriber.sub_id);
          // If a match is found, replace the existing subscriber with the new one, otherwise keep the old subscriber
          return matchingEntry ? matchingEntry : subscriber;
        }),
        // Add new entries that are not already in state.subscribers
        ...newEntries.filter((newEntry) => !state.subscribers.some((subscriber) => subscriber.sub_id === newEntry.sub_id)),
      ];

      // Sort the subscribers by container_name
      state.subscribers.sort((a, b) => a.container_name.localeCompare(b.container_name));
    });

    builder.addCase(subscriberGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(subscriberPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const insertedIds = action.payload.data?.data || [];

      const newSubscribers = action.payload.subscribers.map((subscriber, index) => {
        return {
          ...subscriber,
          sub_id: insertedIds[index],
        };
      });

      state.subscribers = [...state.subscribers, ...newSubscribers];
      state.subscribers.sort((a, b) => a.container_name.localeCompare(b.container_name));
    });

    builder.addCase(subscriberPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(subscriberPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      action.payload.subscribers.map((subscriber) => {
        const existingIndex = state.subscribers.findIndex((entry) => entry.sub_id === subscriber.sub_id);
        if (existingIndex !== -1) {
          state.subscribers[existingIndex] = subscriber;
        }
      });

      state.subscribers.sort((a, b) => a.container_name.localeCompare(b.container_name));
    });

    builder.addCase(subscriberPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(subscriberDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.subscribers = state.subscribers.filter((subscriber) => subscriber.sub_id !== action.payload.subID);
    });

    builder.addCase(subscriberDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberPostContainerStart.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(subscriberPostContainerStart.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(subscriberPostContainerStart.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberPostContainerStop.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(subscriberPostContainerStop.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(subscriberPostContainerStop.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberGetContainerList.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(subscriberGetContainerList.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.containers = action.payload.data;
    });

    builder.addCase(subscriberGetContainerList.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.containers = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberGetContainerLogs.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(subscriberGetContainerLogs.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.logs = action.payload.data;
    });

    builder.addCase(subscriberGetContainerLogs.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.logs = '';
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberGetContainerStats.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(subscriberGetContainerStats.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.stats = action.payload.data;
    });

    builder.addCase(subscriberGetContainerStats.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.stats = {};
      state.errorMessage = action.payload;
    });
  },
});

export default subscriberSlice.reducer;
