import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  databases: [],
  databaseInfo: [],
  error: null,
  success: false,
  errorMessage: null,
};

export const databaseGet = createAsyncThunk('database/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/database';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const databaseGetInfo = createAsyncThunk(
  'database/getInfo',
  async ({ databaseName, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!databaseName) {
        return rejectWithValue({ message: 'Database name and table name are required' });
      }

      let url = '/api/database/info';
      if (databaseName) {
        url += `/${databaseName}`;
      }
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const databasePost = createAsyncThunk('database/post', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/database';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.post(url, payload, config);
    return { ...response, payload, queryParams };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const databasePut = createAsyncThunk('database/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/database';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.put(url, payload, config);
    return { ...response, payload };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const databaseAccessPost = createAsyncThunk(
  'database/access/post',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/database/access';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const databaseAccessPut = createAsyncThunk(
  'database/access/put',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/database/access';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.put(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const databaseAccessDelete = createAsyncThunk(
  'database/access/delete',
  async ({ databaseName, organization, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!databaseName || !organization) {
        return rejectWithValue({ message: 'Database name and organization are required' });
      }

      let url = `/api/database/access/${databaseName}/${organization}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.delete(url, config);
      return { ...response, databaseName, organization };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const databaseSlice = createSlice({
  name: 'database',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(databaseGet.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databaseGet.fulfilled, (state, action) => {
      state.loading = false;
      state.databases = action.payload.data;

      const newEntries = action.payload.data || [];
      state.databases = [
        ...state.databases.map((database) => {
          // Find a matching new entry based on database_name
          const matchingEntry = newEntries.find((newEntry) => newEntry.database_name === database.database_name);
          // If a match is found, replace the existing database with the new one, otherwise keep the old database
          return matchingEntry ? matchingEntry : database;
        }),
        // Add new entries that are not already in state.databases
        ...newEntries.filter((newEntry) => !state.databases.some((database) => database.database_name === newEntry.database_name)),
      ];

      // Sort the databases by database_name
      state.databases.sort((a, b) => a.database_name.localeCompare(b.database_name));
    });

    builder.addCase(databaseGet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databaseGetInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databaseGetInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.databaseInfo = action.payload.data;
    });

    builder.addCase(databaseGetInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databasePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databasePost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntry = {
        database_name: action.payload.payload?.name,
        organization: action.payload.queryParams?.organization,
        access: [],
      };

      // Update state.databases by filtering out existing entries and adding the new entry
      state.databases = [...state.databases.filter((database) => database.database_name !== newEntry.database_name), newEntry];

      // Sort the databases by database_name
      state.databases.sort((a, b) => a.database_name.localeCompare(b.database_name));
    });

    builder.addCase(databasePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databasePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databasePut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(databasePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databaseAccessPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databaseAccessPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newAccessEntries = action.payload.payload?.databaseAccesses || [];

      state.databases = state.databases.map((database) => {
        // Find if there's a matching database in newAccessEntries
        const matchingAccess = newAccessEntries.filter((entry) => entry.database_name === database.database_name);

        if (matchingAccess.length > 0) {
          const updatedAccesses = matchingAccess.map((entry) => ({
            organization: entry.organization,
            permission: entry.permission,
          }));

          return {
            ...database,
            access: [...database.access, ...updatedAccesses],
          };
        }

        // If no match, return the database as-is
        return database;
      });
    });

    builder.addCase(databaseAccessPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databaseAccessPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databaseAccessPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newAccessEntries = action.payload.payload?.databaseAccesses || [];

      state.databases = state.databases.map((database) => {
        // Find matching access entries for the current database
        const matchingAccesses = newAccessEntries.filter((entry) => entry.database_name === database.database_name);

        if (matchingAccesses.length > 0) {
          const updatedAccesses = database.access.map((access) => {
            // Check if there is a matching organization to update the permission
            const matchingAccess = matchingAccesses.find((entry) => entry.organization === access.organization);
            if (matchingAccess) {
              return {
                organization: matchingAccess.organization,
                permission: matchingAccess.permission,
              };
            }
            // If no matching organization, keep the current access as is
            return access;
          });

          return {
            ...database,
            access: updatedAccesses,
          };
        }

        // Return the database as-is if no matching entries
        return database;
      });
    });

    builder.addCase(databaseAccessPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databaseAccessDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databaseAccessDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const { databaseName, organization } = action.payload;

      state.databases = state.databases.map((database) => {
        if (database.database_name === databaseName) {
          return {
            ...database,
            // Filter out the access entry with the matching organization
            access: database.access.filter((item) => item.organization !== organization),
          };
        }
        // Return the database unchanged if no match
        return database;
      });
    });

    builder.addCase(databaseAccessDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default databaseSlice.reducer;
