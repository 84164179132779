export const userRolesFlatten = (userRoles) =>
  userRoles.flatMap((userRole) =>
    userRole.organizations.map((organization) => ({
      user_id: userRole.user.user_id,
      email: userRole.user.email,
      name_family: userRole.user.name_family,
      name_given: userRole.user.name_given,
      organization: organization.organization,
      description: organization.description,
      role: organization.role,
      profile_picture: userRole.user.profile_picture,
    }))
  );

export const userPermissionsFlatten = (userPermissions) =>
  userPermissions.flatMap((userPermission) =>
    userPermission.permissions.map((permission) => ({
      user_id: userPermission.user_id,
      email: userPermission.email,
      name_family: userPermission.name_family,
      name_given: userPermission.name_given,
      profile_picture: userPermission.profile_picture,
      database_name: permission.database_name,
      table_name: permission.table_name,
      permission_type: permission.permission_type,
    }))
  );
