import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import moment from 'moment-timezone';

import axios from 'api';

const initialState = {
  loading: false,
  grafana: [],
  success: false,
  errorMessage: null,
};

export const grafanaGet = createAsyncThunk(
  'grafana/get',
  async ({ databaseName, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!databaseName) {
        return rejectWithValue({ message: 'Database name is required' });
      }

      let url = `/api/grafana/${databaseName}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return {
        ...response,
        databaseName,
        tableName: queryParams?.table || 'measurement',
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const grafanaGetLast = createAsyncThunk(
  'grafana/get/last',
  async ({ databaseName, relativeTime, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!databaseName && !relativeTime) {
        return rejectWithValue({ message: 'Database name and relative time are required' });
      }

      let url = `/api/grafana/${databaseName}/last/${relativeTime}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return {
        ...response,
        databaseName,
        tableName: queryParams?.table || 'measurement',
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const grafanaGetLatest = createAsyncThunk(
  'grafana/get/lastest',
  async ({ databaseName, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!databaseName) {
        return rejectWithValue({ message: 'Database name is required' });
      }

      let url = `/api/grafana/${databaseName}/latest`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return {
        ...response,
        databaseName,
        tableName: queryParams?.table || 'measurement',
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const grafanaSlice = createSlice({
  name: 'grafana',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(grafanaGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(grafanaGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data?.data || [];
      const { databaseName, tableName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
          tableName,
        };
        const existingIndex = state.grafana.findIndex(
          (entry) =>
            entry.local_time === newEntry.local_time &&
            entry.source === newEntry.source &&
            entry.type === newEntry.type &&
            entry.topic === newEntry.topic &&
            entry.databaseName === databaseName &&
            entry.tableName === tableName
        );

        if (existingIndex !== -1) {
          state.grafana[existingIndex] = modifiedEntry;
        } else {
          state.grafana.push(modifiedEntry);
        }
      });

      state.grafana.sort((a, b) => {
        return moment(a.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment(b.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      });
    });

    builder.addCase(grafanaGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.grafana = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(grafanaGetLast.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(grafanaGetLast.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data?.data || [];
      const { databaseName, tableName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
          tableName,
        };
        const existingIndex = state.grafana.findIndex(
          (entry) =>
            entry.local_time === newEntry.local_time &&
            entry.source === newEntry.source &&
            entry.type === newEntry.type &&
            entry.topic === newEntry.topic &&
            entry.databaseName === databaseName &&
            entry.tableName === tableName
        );

        if (existingIndex !== -1) {
          state.grafana[existingIndex] = modifiedEntry;
        } else {
          state.grafana.push(modifiedEntry);
        }
      });

      state.grafana.sort((a, b) => {
        return moment(a.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment(b.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      });
    });

    builder.addCase(grafanaGetLast.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.grafana = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(grafanaGetLatest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(grafanaGetLatest.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data?.data || [];
      const { databaseName, tableName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
          tableName,
        };
        const existingIndex = state.grafana.findIndex(
          (entry) =>
            entry.local_time === newEntry.local_time &&
            entry.source === newEntry.source &&
            entry.type === newEntry.type &&
            entry.topic === newEntry.topic &&
            entry.databaseName === databaseName &&
            entry.tableName === tableName
        );

        if (existingIndex !== -1) {
          state.grafana[existingIndex] = modifiedEntry;
        } else {
          state.grafana.push(modifiedEntry);
        }
      });

      state.grafana.sort((a, b) => {
        return moment(a.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment(b.local_time, 'YYYY-MM-DD HH:mm:ss').valueOf();
      });
    });

    builder.addCase(grafanaGetLatest.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.grafana = [];
      state.errorMessage = action.payload;
    });
  },
});

export default grafanaSlice.reducer;
