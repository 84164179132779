import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Chip, ClickAwayListener, List, ListItemButton, Popper, Stack, Typography } from '@mui/material';

// project imports
import BackgroundLetterAvatar from 'ui-component/BackgroundLetterAvatar';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import blurEffect from 'utils/blurEffect';

// assets
import { IconHomeShield, IconList, IconSelector, IconWorldShare } from '@tabler/icons-react';

const WorkspaceSection = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { orgID } = useParams();

  const customization = useSelector((state) => state.customization);
  const { account } = useSelector((state) => state.account);
  const organization = account?.organizations.find((org) => org.organization === orgID);
  const blur = blurEffect({ blurStrength: 20, backgroundOpacity: 0.6, theme });

  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const avatarSx = {
    size: 34,
    sx: {
      ...theme.typography.mediumAvatar,
      cursor: 'pointer',
    },
  };
  let chipIcon;
  if (orgID === 'superuser' || orgID === 'public') {
    chipIcon = (
      <Avatar
        size={34}
        sx={{
          ...theme.typography.mediumAvatar,
          margin: '8px 0 8px 8px !important',
          cursor: 'pointer',
        }}
      >
        {orgID === 'superuser' ? <IconHomeShield /> : <IconWorldShare />}
      </Avatar>
    );
  } else {
    chipIcon = organization ? (
      <BackgroundLetterAvatar
        name={organization.description}
        size={34}
        sx={{
          ...theme.typography.mediumAvatar,
          margin: '8px 0 8px 8px !important',
          cursor: 'pointer',
        }}
      />
    ) : (
      <Avatar
        size={34}
        sx={{
          ...theme.typography.mediumAvatar,
          margin: '8px 0 8px 8px !important',
          cursor: 'pointer',
        }}
      >
        <IconList />
      </Avatar>
    );
  }

  let chipLabel;
  if (orgID === 'superuser') {
    chipLabel = 'Platform Management Dashboard';
  } else if (orgID === 'public') {
    chipLabel = 'Public Data';
  } else {
    chipLabel = organization?.description || 'Select Workspace';
  }

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.text.primary,
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
          mx: 1,
        }}
        icon={chipIcon}
        label={
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body1" color="textPrimary" sx={{ display: { xs: 'none', sm: 'block' } }}>
              {chipLabel}
            </Typography>
            <IconSelector size={18} />
          </Stack>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <MainCard
                elevation={20}
                content={false}
                boxShadow
                shadow={theme.shadows[16]}
                sx={{ ...blur, minWidth: { xs: 'auto', sm: '350px' }, maxWidth: '400px' }}
              >
                <Box
                  sx={{
                    px: 1,
                    maxHeight: 'calc(100vh - 80px)',
                    overflowY: 'auto',
                  }}
                >
                  <List>
                    {account?.role === 'superuser' && (
                      <ListItemButton
                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                        onClick={() => {
                          navigate(`/app/superuser/home`, { replace: false });
                        }}
                      >
                        <Avatar {...avatarSx}>
                          <IconHomeShield />
                        </Avatar>
                        <Box flex="1" sx={{ pl: 1, pr: 2 }}>
                          <Typography variant="body1">Platform Management Dashboard</Typography>
                        </Box>
                      </ListItemButton>
                    )}
                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      onClick={() => {
                        navigate(`/app/public/home`, { replace: false });
                      }}
                    >
                      <Avatar {...avatarSx}>
                        <IconWorldShare />
                      </Avatar>
                      <Box flex="1" sx={{ pl: 1, pr: 2 }}>
                        <Typography variant="body1">Public Data</Typography>
                      </Box>
                    </ListItemButton>
                    {(account?.organizations || []).map((organization) => {
                      let chipColor;
                      if (organization.approval_status === 'pending' || organization.status === 'inactive') {
                        chipColor = 'default';
                      } else if (organization.approval_status === 'rejected' || organization.status === 'disabled') {
                        chipColor = 'error';
                      }
                      const chipLabel =
                        organization.approval_status !== 'approved'
                          ? organization.approval_status.replace(/\b\w/g, (c) => c.toUpperCase())
                          : organization.status.replace(/\b\w/g, (c) => c.toUpperCase());
                      const secondaryAction =
                        organization.status !== 'active' || organization.approval_status !== 'approved' ? (
                          <Chip label={chipLabel} color={chipColor} size="small" />
                        ) : null;
                      return (
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          key={`org-id-${organization.organization}`}
                          onClick={() => {
                            navigate(`/app/${organization.organization}/home`, { replace: false });
                          }}
                          disabled={
                            (organization.status !== 'active' || organization.approval_status !== 'approved') &&
                            account.role !== 'superuser'
                          }
                        >
                          <BackgroundLetterAvatar name={organization.description} sx={{ width: 32, height: 32 }} size="small" />
                          <Box flex="1" sx={{ pl: 1, pr: 2 }}>
                            <Typography variant="body1">{organization.description}</Typography>
                            <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                              {organization.role}
                            </Typography>
                          </Box>
                          {secondaryAction}
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Box>
              </MainCard>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default WorkspaceSection;
