import axios from 'axios';

const baseURL =
  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV || 'http://localhost:8080' : window.location.origin;

export default axios.create({
  baseURL,
});

export { baseURL };
