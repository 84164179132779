import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  sources: [],
  success: false,
  error: null,
  errorMessage: null,
};

export const sourceGet = createAsyncThunk('source/get', async ({ databaseName, urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    if (!databaseName) {
      return rejectWithValue({ message: 'Database name is required' });
    }

    let url = `/api/source/${databaseName}`;

    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);

    return {
      ...response,
      databaseName,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const sourcePost = createAsyncThunk(
  'source/post',
  async ({ databaseName, urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!databaseName) {
        return rejectWithValue({ message: 'Database name is required' });
      }

      let url = `/api/source/${databaseName}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sourcePut = createAsyncThunk(
  'source/put',
  async ({ databaseName, urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!databaseName) {
        return rejectWithValue({ message: 'Database name is required' });
      }

      let url = `/api/source/${databaseName}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.put(url, payload, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sourceDelete = createAsyncThunk(
  'source/delete',
  async ({ databaseName, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!databaseName) {
        return rejectWithValue({ message: 'Database name is required' });
      }

      let url = `/api/source/${databaseName}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.delete(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const sourceSlice = createSlice({
  name: 'source',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sourceGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(sourceGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.data?.data || [];
      const { databaseName } = action.payload;

      newEntries.forEach((newEntry) => {
        const modifiedEntry = {
          ...newEntry,
          databaseName,
        };

        const existingIndex = state.sources.findIndex((entry) => entry.source === newEntry.source && entry.databaseName === databaseName);
        if (existingIndex !== -1) {
          state.sources[existingIndex] = modifiedEntry;
        } else {
          state.sources.push(modifiedEntry);
        }
      });

      state.sources.sort((a, b) => {
        return a.source.localeCompare(b.source);
      });
    });

    builder.addCase(sourceGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.sources = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(sourcePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(sourcePost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(sourcePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(sourcePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(sourcePut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(sourcePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(sourceDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(sourceDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(sourceDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default sourceSlice.reducer;
