import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { accountGetDetails } from 'features/account/accountSlice';
import Loader from 'ui-component/Loader';
import handleAuthentication from 'utils/handleAuthentication';

const pathWithWarning = ['/join'];

const WithAuthentication = ({ children }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isFirstLoad = useRef(true);
  const { isAuthenticated } = useSelector((state) => state.account);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const newParams = new URLSearchParams(searchParams);
      const token = newParams.get('token');

      if (token) {
        // Handle authentication with the token from URL
        await handleAuthentication(dispatch, token);
        // remove the token from the URL
        newParams.delete('token');
        setSearchParams(newParams);
      } else if (isAuthenticated && isFirstLoad.current) {
        // Fetch user details if already authenticated and this is the first load
        await dispatch(accountGetDetails()).unwrap();
      }

      setIsLoading(false);
    };

    checkAuth();
  }, [dispatch, isAuthenticated, searchParams, setSearchParams]);

  if (isLoading) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return children;
  }

  return (
    <Navigate
      to="/login"
      replace
      state={{
        from: `${window.location.pathname}${window.location.search}`,
        alertMessage: pathWithWarning.includes(window.location.pathname) ? 'You need to login to access this page' : null,
        alertSeverity: pathWithWarning.includes(window.location.pathname) ? 'warning' : null,
      }}
    />
  );
};

WithAuthentication.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WithAuthentication;
