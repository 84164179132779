import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// material-ui
import { styled } from '@mui/material/styles';
import { Avatar, Card, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// project imports
import BackgroundLetterAvatar from 'ui-component/BackgroundLetterAvatar';

//assets
import { IconHomeShield, IconWorldShare } from '@tabler/icons-react';

// styles
const CardStyle = styled(Card)(({ theme }) => ({
  background: theme.palette.warning.light,
  marginTop: '16px',
  // marginBottom: '16px',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '200px',
    height: '200px',
    border: '19px solid ',
    borderColor: theme.palette.warning.main,
    borderRadius: '50%',
    top: '15px',
    right: '-150px',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '200px',
    height: '200px',
    border: '3px solid ',
    borderColor: theme.palette.warning.main,
    borderRadius: '50%',
    top: '85px',
    right: '-70px',
  },
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const CurrentOrgCard = () => {
  const customization = useSelector((state) => state.customization);
  const { account } = useSelector((state) => state.account);
  const { orgID } = useParams();

  let { description, role } = account.organizations.find(({ organization }) => organization === orgID) || {
    description: 'Organization',
  };

  // Custom conditions for superuser and public orgID
  if (orgID === 'superuser') {
    description = 'Platform Management Dashboard';
    role = 'Super User';
  } else if (orgID === 'public') {
    description = 'Public Data';
    role = 'member';
  }

  let avatar = <BackgroundLetterAvatar name={description} />;

  if (orgID === 'superuser') {
    avatar = (
      <Avatar>
        <IconHomeShield />
      </Avatar>
    );
  } else if (orgID === 'public') {
    avatar = (
      <Avatar>
        <IconWorldShare />
      </Avatar>
    );
  }

  return (
    <CardStyle>
      <List>
        <ListItem
          sx={{
            borderRadius: `${customization.borderRadius}px`,
            backgroundColor: 'inherit',
          }}
        >
          <ListItemAvatar>{avatar}</ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="body1" color="inherit">
                {description}
              </Typography>
            }
            secondary={
              <Typography variant="caption" color="inherit" sx={{ textTransform: 'capitalize' }}>
                {role}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </CardStyle>
  );
};

export default CurrentOrgCard;
